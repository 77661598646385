import React, { useState, useEffect } from "react";
import "./Profile.css";
import Attachment from "../../assets/Attach.png";
import ArrTop from "../../assets/ArrowTop.png";
import Refresh from "../../assets/refresh.png";
import Dots from "../../assets/dots.png";
import Arrdown from "../../assets/Arrowdown.png";
import Filter from "../../assets/filter.png";
import Union from "../../assets/Union.png";
import { Oval } from 'react-loader-spinner';
import { CSVLink } from "react-csv";
import CSV from '../../assets/csv.png';
import { Modal, Button, Form } from 'react-bootstrap';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NoDataFound from '../../assets/data_not_found.png';
import UserImage from '../../assets/Profile.png';
import Tick from '../../assets/Tick.png'

function ProfileRight({ data, style, allUsers }) {
  // console.log("🚀 ~ ProfileRight ~ allUsers:", allUsers);
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    password: '',
    fullName: '',
    role: '',
    userId: '',
    publicKey: '',
    ipAddress: '',
    company: ''
  })


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
        ...formData,
        [name]: value
    });
};

  const handleSubmit = (event) => {
    event.preventDefault();
    const { userName, password, fullName, role, userId, publicKey, ipAddress, company } = formData;
    alert(`
  ____Your Details____\n
  userName: ${userName}
  password: ${password}
  fullName: ${fullName}
  role: ${role}
  user Id: ${userId}
  publicKey: ${publicKey}
  ipAddress: ${ipAddress}
  company: ${company}
`);
  };
  return (
    <div className="admin-form container">
    <div style={{display: 'flex', flexDirection: 'row'}}>
    <div className="profile-picture">
      <img src={UserImage} alt="Admin" />
    </div>
      <div className="nameEmail">
      <h4 style={{color: '#fff'}}> Admin 001 </h4>
      <h6 style={{color: '#fff'}}> admin@altatechmark.com - Administrator </h6>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
    <div className="account"> 
    <h4 style={{color: '#fff'}}> Account </h4>   
    <div className="text-center">
        <button type="submit" className="btn btn-primary">   <img src={Tick} style={{marginRight: 10}} /> Save</button>
    </div>
    </div>
      <div className="form-group row">
        <label htmlFor="username" className="col-sm-3 col-form-label">Username</label>
        <div className="col-sm-9">
          <input type="text" className="form-control" name="userName" id="username"  placeholder="-----------" value={formData.userName}
              onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="email" className="col-sm-3 col-form-label">Email</label>
        <div className="col-sm-9">
          <input type="email" className="form-control" name="email" id="email"  placeholder="-----------" value={formData.email}
              onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="password" className="col-sm-3 col-form-label">Password</label>
        <div className="col-sm-9">
          <input type="password" className="form-control" name="password" id="password"  placeholder="-----------" value={formData.password}
              onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="fullname" className="col-sm-3 col-form-label">Full Name</label>
        <div className="col-sm-9">
          <input type="text" className="form-control" name="fullName" id="fullname"  placeholder="-----------" value={formData.fullName}
              onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="role" className="col-sm-3 col-form-label">Role</label>
        <div className="col-sm-9">
          <input type="text" className="form-control" name="role" id="role"  placeholder="-----------" value={formData.role}
              onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="user-id" className="col-sm-3 col-form-label">User ID</label>
        <div className="col-sm-9">
          <input type="text" className="form-control" name="userId" id="user-id"  placeholder="-----------" value={formData.userId}
              onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="public-key" className="col-sm-3 col-form-label">Public Key</label>
        <div className="col-sm-9">
          <input type="text" className="form-control" name="publicKey" id="public-key"  placeholder="-----------" value={formData.publicKey}
              onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="ip-address" className="col-sm-3 col-form-label">IP Address</label>
        <div className="col-sm-9">
          <input type="text" className="form-control" name="ipAddress" id="ip-address" placeholder="-----------" value={formData.ipAddress}
              onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="ip-address" className="col-sm-3 col-form-label">Company</label>
        <div className="col-sm-9">
          <input type="text" className="form-control" name="company" id="ip-address" placeholder="-----------" value={formData.company}
              onChange={handleChange}/>
        </div>
      </div>
    </form>
  </div>
  );
}

export default ProfileRight;
