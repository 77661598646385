import React, { useState } from 'react';
import './Tooltip.css'; // Make sure to update the CSS accordingly

const Tooltip = ({ children, content }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div className="tooltip-wrapper" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {visible && (
        <div className="tooltip-box">
          {content.map((item, index) => (
            <div key={index} className="tooltip-item">
              {item.label}: {item.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
