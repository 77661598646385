import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import logo from "../../assets/EnigmaLogo.png";
import vector from "../../assets/Vector.png";
import Profile from '../../assets/Profile.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";

function Sidebar() {
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call on mount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDropdown = () => {
    navigate('/UserManagement');
    setisDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      {isMobile && (
        <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      )}
      <aside className={`App-sidebar ${isOpen ? 'open' : ''}`}>
        <div className="logo-section">
          <img src={logo} width={150} height={35} />
          <span className="title">Hek Management Console</span>
        </div>
        <div className="list-section">
        <span
            className={`simple-link ${location.pathname === '/Dashboard' ? 'active' : ''}`}
            onClick={() => {
              navigate('/Dashboard');
            }}
          >
           Dashboard
          </span>
          <span
            onClick={toggleDropdown}
            className={`dropbtn ${location.pathname === '/UserManagement' ? 'active' : ''}`}
          >
            User Management 
            {/* <img src={vector} /> */}
          </span>
          {/* {isDropdownOpen && (
            <div className="dropdown-content">
              <a
                onClick={() => {
                  navigate('/Permission');
                }}
                className={location.pathname === '/Permission' ? 'active' : ''}
              >
                Permissions
              </a>
            </div>
          )} */}
          <span
            className={`simple-link ${location.pathname === '/HekDeviceManagement' ? 'active' : ''}`}
            onClick={() => {
              navigate('/HekDeviceManagement');
            }}
          >
            Hek Device Management
          </span>
          <span
            className={`simple-link ${location.pathname === '/LedgerEvent' ? 'active' : ''}`}
            onClick={() => {
              navigate('/LedgerEvent');
            }}
          >
            Ledger Operations
          </span>
          <span
            className={`simple-link ${location.pathname === '/NetworkConnectivity' ? 'active' : ''}`}
            onClick={() => {
              navigate('/NetworkConnectivity');
            }}
          >
           Networking & Connectivity
          </span>
          <span
            className={`simple-link ${location.pathname === '/Support-and-Documentation' || location.pathname === '/SupportDetails' ? 'active' : ''}`}
            onClick={() => {
              navigate('/Support-and-Documentation');
            }}
          >
           Support & Documentation
          </span>
         
          {/* Profile link container */}
          <div className="profile-link-container">
            <span
              className={`simple-link ${location.pathname === '/Profile' ? 'active' : ''}`}
              style={{backgroundColor: '#D11C1C', width: '50%', padding: '25px 100px 25px 0px', borderTopRightRadius: '10px', borderBottomLeftRadius: '10px'}}
              onClick={() => {
                navigate('/Profile');
              }}
            >
              <img src={Profile} style={{ width: 50, height: 50, margin: '5px 10px' }} alt="Back" />
              Admin
            </span>
          </div>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
