import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./SupportAndDocumentationCard.css";

import TicketMiddleware from "../../Redux/Middleware/TicketMiddleWare";

import Attachment from "../../assets/Attach.png";
import ArrTop from "../../assets/ArrowTop.png";
import Refresh from "../../assets/refresh.png";
import Dots from "../../assets/dots.png";
import Arrdown from "../../assets/Arrowdown.png";
import Filter from "../../assets/filter.png";
import Search from '../../assets/search.png'
import Union from "../../assets/Union.png";
import { Oval } from 'react-loader-spinner';
import { CSVLink } from "react-csv";
import CSV from '../../assets/csv.png';
import { Modal, Button, Form } from 'react-bootstrap';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NoDataFound from '../../assets/data_not_found.png';
import UserImage from '../../assets/Profile.png';
import Tick from '../../assets/Tick.png'
import axios from "axios";

function SupportAndDocumentationCard() {

  const {ticket} = useSelector((state) => state)
  // console.log("🚀 ~ SupportAndDocumentationCard ~ ticket:", ticket)
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    password: '',
    fullName: '',
    role: '',
    userId: '',
    publicKey: '',
    ipAddress: '',
    company: ''
  });

  const dispatch = useDispatch();


    const [userData, setUserData] = useState({});

  const GetUserData = async () => {
    try {
      const adminData = await localStorage.getItem('userData')
      setUserData(adminData);
    } catch (error) {
      console.log("🚀 ~ GetUserData ~ error:", error)
    }
  };

  useEffect(() => {
    GetUserData();
    dispatch(TicketMiddleware.GetAllTickets());
  }, []);

  return (
    <> 
    <div className="filter-main">
    <div className="search-box">
            <input type="text" placeholder="Search"  
            // value={searchTerm} onChange={handleSearch} 
            />
            <button className="search-icon">
              <img src={Search} width={18} height={16} />
            </button>
          </div>
          
          <div className="header">
  <div className="headerTitle">
    <button
      className="iconbutton"
      style={{ fontSize: 16, fontWeight: 'bold', color: '#fff' }}
      // onClick={() => setIsFilterModalOpen(true)}
    >
      <img src={Filter} width={30} height={30} alt="Filter" /> 
      {/* Filter */}
    </button>
  </div>
  <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginRight: 20}}> 
    <button className="statusBtn"> New Ticket </button>
  </div>
</div>

          </div>

          {ticket.allTickets && ticket.allTickets.length > 0 ? (
  ticket.allTickets.map((data, index) => {
    if (!data) return null; // Skip if data is invalid
    return (
      <div className="card-main" style={{width: '97%'}} key={index || data?.TicketID}>
        <div className="top-content">
          <Link to={`/SupportDetails/${data?.TicketID}`}
            state={{ selectedComplainData: data, adminData: userData }} 
          >
            <h3 style={{color: '#fff'}}> {data?.ComplainType} </h3>
          </Link>
          <p style={{color: '#fff'}}> {moment(data?.Created).format("Do MMM YY")} </p>
        </div>
        <p style={{color: '#fff'}}> {data?.ComplainDetail} </p>
        <button className="statusBtn"> {data?.Priority} </button>
        <button className="statusBtn" style={{backgroundColor: '#fff', color: '#000', marginLeft: '20px'}}> #{data?.TicketID} </button>
      </div>
    );
  })
) : (
  <p>No tickets available</p>
)}

         

  </>
  );
}

export default SupportAndDocumentationCard;
