import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LedgerTable from "../../Component/Table/LedgerTable";
import LedgerMiddleware from "../../Redux/Middleware/LedgerMiddleware";
import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";
import SupportAndDocumentationCard from "../../Component/SupportAndDocumentationCard";

const SupportAndDocumentation = () => {
  const { Ledgers } = useSelector((state) => state.ledger);
  const { allUsers } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const token = localStorage.getItem("userData");

  useEffect(() => {
    dispatch(LedgerMiddleware.getLedgerEntries());
    dispatch(AuthMiddleware.getAllUsers());
  }, [dispatch, token]);

  return (
    <div>
      <SupportAndDocumentationCard
        data={[Ledgers.ledger_entries]}
        style={{ height: "100vh" }}
        allUsers={allUsers?.users}
      />
    </div>
  );
};

export default SupportAndDocumentation;
