import ApiCaller from "../../ApiHeader/ApiCaller";
import TicketAction from "../Action/Tickets";

const getBearerHeaders = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found in local storage.");
  }
  return ApiCaller.BearerHeaders(token); // Ensure it's a string
};

export default class TicketMiddleware {

  static GetAllTickets() {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller.Get("get_tickets");
          if (response.status === 201 || response.status === 200) {
            resolve(response.data);
            dispatch(TicketAction.GetAllTickets(response.data));
          } 
        } catch (error) {
          console.error("Error fetching tickets:", error);
          reject(error);
        }
      });
    };
  }

  static UpdateTicket(ticketId, updateData) {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller.Patch(
            `update_ticket/${ticketId}`,
            updateData,
            getBearerHeaders()
          );

          if (response.status === 200 || response.status === 204) {
            resolve(response.data);
            dispatch(TicketAction.UpdateTicket(ticketId, updateData));
          } else {
            reject(new Error("Failed to update ticket"));
          }
        } catch (error) {
          console.error("Error updating ticket:", error);
          reject(error);
        }
      });
    };
  }
}
