import React, { useEffect, useState } from "react";
import "./VPNConnection.css";
import ArrowDown from "../../assets/Arrowdown.png";
import { useDispatch, useSelector } from "react-redux";
import VPNConfigMiddleware from "../../Redux/Middleware/VPNConfigMiddleware";
import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";
import HeksMiddleware from "../../Redux/Middleware/HekMiddleware";
const VPNConnection = () => {
  const [selected, setselected] = useState("");
  const [user_id, setuser_id] = useState("");
  const [clientPublicKey, setClientPublicKey] = useState("");
  const [Result, setResult] = useState();
  // console.log("🚀 ~ VPNConnection ~ Result:", Result)

  const publicKeyRegex = /\[Peer\]\s*PublicKey\s*=\s*([^\n]+)/;

  // Find the PublicKey using the regular expression

  // console.log("PublicKey:", match[1].trim());
  // if (match) {
  //   console.log("PublicKey:", match[1].trim());
  // } else {
  //   console.log("PublicKey not found.");
  // }

  const { allUsers } = useSelector((state) => state.auth);
  const { Heks } = useSelector((state) => state.heks);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AuthMiddleware.getAllUsers());
    dispatch(HeksMiddleware.getHeks());
  }, [dispatch]);

  const handlesubmit = async () => {
    try {
      const requestData = {
        client_public_key: clientPublicKey,
        selected: selected,
      };
      if (selected === "User") {
        const selectedUser = allUsers.users.find(
          (el) => el.username === user_id
        );
        requestData.user_id = selectedUser?.id;
      } else if (selected === "HEK") {
        const selectedHEK = Heks.heks.find(
          (el) => el.Serial_Number === user_id
        );
        requestData.hek_id = selectedHEK?.id;
      }
      await dispatch(VPNConfigMiddleware.generate_config(requestData))
        .then((result) => {
          setResult(result);
        })
        .catch((error) => {
          alert(error.response.data.error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (event) => {
    setuser_id(event.target.value);
  };

  const handleRadioChange = (event) => {
    setselected(event.target.value);
  };
  const allUser = allUsers?.users?.map((el) => el);
  const allHek = Heks?.heks?.map((el) => el);

  const downloadFile = (filename, content) => {
    // Create a Blob with the content
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    // Create a link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    // Append the link to the body
    document.body.appendChild(link);
    // Trigger the download
    link.click();
    // Remove the link from the body
    document.body.removeChild(link);
  };

  const handleDownload = () => {
    // Example content to download
    const content = Result;
    const match = Result.match(publicKeyRegex);
    // Call the download function
    downloadFile(`${match[1].trim()}.txt`, content);
  };

  return (
    <div className="network-container">
      <div className="heading-section">
        <text style={{color: '#fff'}}>New VPN Connection Setup</text>
      </div>
      <div className="input-container">
        <div className="container-1">
          <div className="radio-container">
            <label>
              <input
                type="radio"
                name="User"
                value="User"
                checked={selected === "User"}
                onChange={handleRadioChange}
              />
              <span className="labelStyle" style={{color: '#fff'}}>User</span>
            </label>
            {/* <label>
              <input
                type="radio"
                name="HEK"
                value="HEK"
                checked={selected === "HEK"}
                onChange={handleRadioChange}
              />
              <span className="labelStyle">HEK</span>
            </label> */}
          </div>
          <div>
            <label className="input-label">
              <span className="labelStyle" style={{ margin: 0, color: '#fff' }}>
                {selected === "HEK" ? "Select HEK" : "Select User"}
              </span>
              <select
                value={user_id}
                onChange={handleSelectChange}
                className="input"
              >
                <option key="default" value="" style={{color: '#fff'}}>
                  Select ...
                </option>
                {selected === "HEK"
                  ? allHek?.map((el, index) => (
                      <option key={index} value={el?.Serial_Number}>
                        {el?.Serial_Number}
                      </option>
                    ))
                  : allUser?.map((el, index) => (
                      <option key={index} value={el?.username} style={{color: '#fff'}}>
                        {el?.username}
                      </option>
                    ))}
              </select>
            </label>
          </div>
        </div>
        <div className="container-2">
          <div>
            <label className="input-label">
              <span className="labelStyle" style={{ margin: 0, color: '#fff' }}>
                Enter Public Key
              </span>
              <input
                type="text"
                className="input"
                value={clientPublicKey}
                onChange={(e) => setClientPublicKey(e.target.value)}
              />
            </label>
          </div>
          <button onClick={handlesubmit}>Submit</button>
        </div>
      </div>
                    {Result && (
      <div className="connection-container">
      <div className="connection-detail">
        <div
          style={{
            gap: "25px",
            flex: "2",
          }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              textEmphasis: "ButtonText",
              color: '#fff'
            }}
          >
            {Result}
          </span>
        </div>
        {Result ? (
<div>
<button className="arr-button" onClick={handleDownload}>
 <img src={ArrowDown} alt="ArrowDown" />
</button>
</div>
        ) : null}
       
      </div>
    </div>
                    )}

    </div>
  );
};

export default VPNConnection;
