import React, { useEffect, useState } from "react";
import "./Login.css";
import logo from "../../assets/EnigmaLogo.png";
import Input from "../../Component/Input/Input";
import Checkbox from "../../Component/Checkbox/Checkbox";
import ApiCaller from "../../ApiHeader/ApiCaller";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(""); //Asad@altatechmark.com
  const [password, setPassword] = useState(""); //Asad123!
  const login = () => {
    dispatch(AuthMiddleware.SignIn({ email, password }))
      .then((result) => {
        localStorage.setItem("userData", JSON.stringify(result));
        localStorage.setItem("token", JSON.stringify(result.access_token));
        if(result?.role === 'Admin'){
          navigate("/Dashboard");
        }else{
          alert('You are not Admin.')
        }

      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {}, [login]);

  return (
    <div className="login">
      <div className="login-header">
        <img src={logo} width={170} height={40} />
      </div>
      <div className="login-content">
        <span style={{ color: "white", fontSize: "35px", fontWeight: "600" }}>
          SIGN IN
        </span>
        <div className="form-section">
          <div className="form">
            <Input
              label={"Email"}
              placeholder={"Enter Email"}
              type={"text"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              label={"Password"}
              placeholder={"Enter Password"}
              type={"password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {/* <div className="forget-password">
            <Checkbox />
            <a href="#"> Forget Password</a>
          </div> */}
          <div>
            <button className="login-button" style={{marginTop: 50}} onClick={login}>
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
