import React from 'react';
import './HomeTop.css'; // Assuming you want to use the same CSS file

const MultiColorProgressBar = ({ readings }) => {
  return (
    <div className="multicolor-bar">
      <div className="values">
        {readings.map((item, i) => item.value > 0 && (
          <div className="value" style={{ color: item.color, width: `${item.value}%` }} key={i}>
            <span>{item.value}%</span>
          </div>
        ))}
      </div>
      <div className="scale">
        {readings.map((item, i) => item.value > 0 && (
          <div className="graduation" style={{ color: item.color, width: `${item.value}%` }} key={i}>
            <span>|</span>
          </div>
        ))}
      </div>
      <div className="bars">
        {readings.map((item, i) => item.value > 0 && (
          <div className="bar" style={{ backgroundColor: item.color, width: `${item.value}%` }} key={i} />
        ))}
      </div>
      <div className="legends">
        {readings.map((item, i) => item.value > 0 && (
          <div className="legend" key={i}>
            <span className="dot" style={{ color: item.color }}>●</span>
            <span className="label">{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiColorProgressBar;
