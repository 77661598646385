import React, { useState, useEffect, useRef, useCallback } from "react";
import {useParams, Link, useLocation} from "react-router-dom";
import io from 'socket.io-client';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";
import TicketMiddleware from "../../Redux/Middleware/TicketMiddleWare";

import "./SupportDetails.css";

import ArrowBack from '../../assets/arrowLeft.png'
import Profile from '../../assets/Profile.png'
import Attach from "../../assets/attach2.png";
import moment from "moment";


function SupportDetails({}) {
  const { id } = useParams();

  const { allUsers } = useSelector((state) => state.auth);

  const token = localStorage.getItem("userData");

  const dispatch = useDispatch();

  const location = useLocation();

  const { selectedComplainData, adminData } = location.state || {}; // Access the data
  console.log("🚀 ~ SupportDetails ~ selectedComplainData:", selectedComplainData)

  
  const [isToggled, setIsToggled] = useState(false);
  const [text, setText] = useState("");
  const [serverMessages, setServerMessages] = useState([]);
  const [groupedMessages, setGroupedMessages] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedId, setSelectedId] = useState(null);
  const [iconsModalVisible, setIconsModalVisible] = useState(false);
  const [isLastMessage, setIsLastMessage] = useState('');
  const [status, setStatus] = useState(selectedComplainData?.Status || "Open");
  console.log("🚀 ~ SupportDetails ~ status:", status)

  const toggleStatus = (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    handleUpdateTicket(newStatus); // Call API function when status changes
  };

  const API_BASE_URL = 'https://enigmakey.tech/serv';

  const socketRef = useRef(null);


  useEffect(() => {
    try {
      if (!socketRef.current) {
        socketRef.current = io('https://enigmakey.tech/chat', {
          transports: ['websocket'],
          jsonp: false,
          query: { senderId: JSON.parse(adminData)?.user_id },
        });
  
        const socket = socketRef.current;
  
        socket.on('connect', () => {
          console.info('Socket connected');
          socket.emit('register', { user_id: JSON.parse(adminData)?.user_id });
          // updateCallerId(userId);
        });
  
        socket.on("new_private_message", (message) => {
          if (!message) {
            console.error("❌ Received an empty message!");
            return;
          } 
          else{
            setServerMessages(prevMessages => [...prevMessages, message]);
            // playMessageTone();
            // setNotifyCount((prev) => prev + 1);
            // setNewMessage(message);
          }
        });
  
  
  
        socket.on('connect_error', (error) => {
          console.error('Socket connection error:', error);
        });
  
        socket.on('disconnect', () => {
          console.warn('Socket disconnected');
        });
      }
  
      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    } catch (error) {
      console.error("🚀 ~ GetUserId ~ error:", error)
      
    }

  }, [adminData])

    useEffect(() => {
      dispatch(AuthMiddleware.getAllUsers());
    }, [token, dispatch]);

  const getMessages = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get_messages?name1=${JSON.parse(adminData)?.username}&name2=${selectedComplainData?.Username}&page=${1}&page_size=${15}`
        // numberOfMessages
      );
  
      const messages = response.data.messages; // Extract messages array


        setServerMessages(messages);

        // setServerMessages((prev) => {
        //   // Remove duplicates based on a unique identifier (e.g., message ID)
        //   const existingIds = new Set(prev.map((msg) => msg.messageId)); // Store existing message IDs
        //   const newMessages = messages.filter((msg) => !existingIds.has(msg.messageId)); // Filter out duplicates
    
        //   return [...prev, ...newMessages]; // Append only unique messages
        // });

    //   setTimeout(() => {
    //     if (scrollRef.current) {
    //         scrollRef.current.scrollToEnd({ animated: true });
    //     }
    // }, 500);
    
  
      // setIsLoading(false);
    } catch (error) {
      console.error("🚀 ~ getMessages ~ error:", error);
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    getMessages();
  }, [adminData, selectedComplainData])

  
  // Step 2: Update groupedMessages whenever serverMessages change
  useEffect(() => {
    if (!serverMessages?.length) return;
    
    const filteredUsers = allUsers?.users?.filter(user =>
      user?.username?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  
    const allCombinedArrays = filteredUsers?.map(usersData => {
      const userMessages = serverMessages.filter(
        msg =>
          (msg.senderName === JSON.parse(adminData).username && msg.receiverName === selectedComplainData.Username) ||
          (msg.senderName === selectedComplainData.Username && msg.receiverName === JSON.parse(adminData).username)
      );
  
      return {
        userId: usersData?.id,
        combinedArray: [...userMessages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      };
    });
  
    const userCombinedArray = allCombinedArrays.find(item => item.userId === selectedComplainData.User_id);
  
    if (userCombinedArray?.combinedArray?.length) {
      const groupedByDate = userCombinedArray.combinedArray.reduce((acc, msg) => {
        const messageDate = moment(msg.timestamp).format("YYYY-MM-DD");
        if (!acc[messageDate]) acc[messageDate] = [];
        acc[messageDate].push(msg);
        return acc;
      }, {});
  
      setGroupedMessages(groupedByDate);
    }
  }, [serverMessages]); // Runs whenever serverMessages updates

    useEffect(() => {
      {Object.keys(groupedMessages).map((date) => {
        if (groupedMessages[date] && groupedMessages[date]?.length > 0) {
          const lastItem = groupedMessages[date][groupedMessages[date]?.length - 1];
          setIsLastMessage(lastItem);
        }
      })}
    }, [groupedMessages, serverMessages]); 

  const sendPrivateMessage = useCallback(async (e) => {
    e.preventDefault();
    if (selectedComplainData && adminData && JSON.parse(adminData).user_id && selectedComplainData.User_id) {

      const message = {
        type: 'text',
        recipient_id: selectedComplainData.User_id,
        data: text,
        sender_id: JSON.parse(adminData).user_id,
      };
      if (socketRef.current) {
        socketRef.current.emit('private_message', message);
        // alert('Message sent to Asad!');
        setText('')
      } else {
        console.error('Something is missing!');
      }
    } else {
      console.error('adminData or selectedComplainData is missing user_id');
    }
  }, [adminData, selectedComplainData, text]);

  const DeleteMessage = async (id) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/delete_message_by_id?messageId=${id}`);

      alert(response?.data?.message)
      
      if (response?.data?.message !== '') {
        setServerMessages((prev) => prev.filter((msg) => msg.messageId !== id)); // Remove deleted message
      }
      
      setIconsModalVisible(false);
    } catch (error) {
      console.error("🚀 ~ DeleteMessage ~ error:", error);
    }
  };

  // const handleUpdateTicket = () => {
  //   const ticketId = selectedComplainData?.TicketID;
  //   const updateData = {
  //     Status: status,
  //   };
  
  //   dispatch(TicketMiddleware.UpdateTicket(ticketId, updateData))
  //     .then(() => {
  //       console.log("Ticket updated successfully");
  //     })
  //     .catch((error) => {
  //       console.error("Error updating ticket:", error);
  //     });
  // };

  const handleUpdateTicket = async (newStatus) => {
    try {
      if (!selectedComplainData || !selectedComplainData?.TicketID) {
        console.error("Invalid ticket data!");
        return;
      }
  
      const ticketId = selectedComplainData.TicketID;
  
      const updateData = {
        Status: newStatus,
        LastMessage: isLastMessage?.text,
        Priority: selectedComplainData?.Priority,
      };
  
      console.log("Sending update request:", updateData);

      const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
    
      if (!token) {
        console.error("No token found!");
        return;
      }
    
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.trim()}`,
        };
  
      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      //   "X-Requested-With": "XMLHttpRequest",
      // };
  
      const response = await axios.patch(
        `${API_BASE_URL}/update_ticket/${ticketId}`,
        updateData,
        { headers }
      );
  
      console.log("Ticket updated successfully", response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating ticket:", error.response?.data || error.message);
    }
  };
  
  
  
  

  // useEffect(() => {
  //   handleUpdateTicket();
  // }, [status])

  // Helper functions for file types
  const isImageFile = (uri) => uri && uri.match(/\.(jpeg|jpg|gif|png)$/i);
  const isVideoFile = (uri) => uri && uri.match(/\.(mp4|mov|avi)$/i);
  const isAudioFile = (uri) => uri && uri.match(/\.(mp3|wav|ogg)$/i);
  const isDocumentFile = (uri) => uri && uri.match(/\.(pdf|docx|txt)$/i);
  const isEncrypted = (uri) => uri && uri.includes("encrypted");

  return (
    <> 
    <div className="filter-main">
    {/* <div className="search-box">
            <input type="text" placeholder="Search"  
            />
            <button className="search-icon">
              <img src={Search} width={18} height={16} />
            </button>
          </div> */}
          
          <div className="header">
  <div className="headerTitle">
    {/* <button
      className="iconbutton"
      style={{ fontSize: 16, fontWeight: 'bold', color: '#fff' }}
    >
      <img src={Filter} width={30} height={30} alt="Filter" /> 
    </button> */}
  </div>
  <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginRight: 20}}> 
    <button className="statusBtn"> New Ticket </button>
  </div>
</div>

          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
          <Link to='/Support-and-Documentation'>  
          <img src={ArrowBack} style={{width: 20, height: 20, margin: '10px 20px'}} />
          </Link>
          <h3 style={{color: '#fff'}}> {selectedComplainData?.ComplainType} </h3>
          <div style={{display: 'flex', justifyContent: 'flex-end', width: '70%', marginRight: 20}}> 
          <button className="statusBtn"> {selectedComplainData?.Priority} </button>
         {/* Dropdown for Status */}
        <select
          className="statusBtn"
          style={{ backgroundColor: "#03781D", marginLeft: 20 }}
          value={status}
          onChange={toggleStatus}
        >
          <option value="Open">Open</option>
          <option value="Closed">Closed</option>
        </select>
          </div>
          </div>

        <div className="row"> 
              {/*1st card  */}
          <div className="card-main" style={{height: '45vh', overflowY: 'scroll'}}>

            {Object.keys(groupedMessages).map((date, dateIndex) => {
              return(
                <div key={dateIndex} className="date-group">
                {/* Date Header */}
                <div className="date-header">{moment(date).format("dddd")}</div>

                {groupedMessages[date].map((item, index) => {
                  const timestamp = new Date(item.timestamp);
                  const time = timestamp.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                  const isSentByCurrentUser = item.senderId === JSON.parse(adminData).user_id;
                  const lastItem = groupedMessages[date][groupedMessages[date]?.length - 1];
                  // setIsLastMessage(lastItem)

                  return(
                    <>
                    {/* Message */}
                    <div
                  key={index}
                  className={`message-container ${
                    isSentByCurrentUser ? "sent" : "received"
                  }`}
                >
                  {/* Forwarded Indicator */}
                  {item?.forward && <div className="forwarded">Forwarded</div>}

                  {/* <img src={Profile} style={{ width: 40, height: 40, margin: '0 20px' }} alt="Back" /> */}

                  {/* Render Message Based on Type */}
                  {isImageFile(item?.uri) ? (
                    <div
                      className="image-message"
                      onClick={() => {
                        setIconsModalVisible(true);
                        setSelectedId(item.messageId);
                      }}
                    >
                      {item?.name}
                    </div>
                  ) : isVideoFile(item?.uri) ? (
                    <div className="video-message">
                      <video controls width="200">
                        <source src={item?.uri} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : isAudioFile(item?.uri) ? (
                    <div className="audio-message">
                      <audio controls>
                        <source src={item?.uri} type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  ) : isDocumentFile(item?.uri) ? (
                    <div
                      className="document-message"
                      // onClick={() => openDocument(item?.uri)}
                    >
                      📑 {item?.name}
                    </div>
                  ) : isEncrypted(item?.uri) ? (
                    <div
                      className="encrypted-message"
                      onClick={() => {
                        setIconsModalVisible(true);
                        setSelectedId(item.messageId);
                      }}
                    >
                      Encrypted File
                    </div>
                  ) : (
                    <div
                    className={`text-message ${
                      isSentByCurrentUser ? "sent-message" : "received-message"
                    }`}
                      onClick={() => {
                        setIconsModalVisible(true);
                        setSelectedId(item.messageId);
                      }}
                    >
                      {item.text}
                    </div>
                  )}

                  {/* Timestamp */}
                  <div className={isSentByCurrentUser ? "sent-timestamp" : "received-timestamp"}>{time}</div>

                  {/* Icons Modal */}
                  {iconsModalVisible && selectedId === item.messageId && (
                    <div className="icons-modal">
                      <button
                      className="delete-btn"
                        onClick={() => {
                          DeleteMessage(selectedId);
                        }}
                      >
                        Delete
                      </button>
                      <button
                      className="close-btn"
                        onClick={() => {
                          setIconsModalVisible(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  )}
                </div>

                  
                    </>
                  )
                })}
                </div>
              )
            })}
 
          </div>

          <div className="card-main" style={{width: '20%'}}>
  {/* First Message Block: Left-aligned */}
  <h4 style={{color: '#fff'}}>  Ticket Info </h4>


<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <h6  style={{color: '#fff', marginTop: '30px', }}> Ticket ID: </h6>
        <div className="ticket-info-bedge" style={{backgroundColor: '#fff'}}> 
        <p  style={{color: '#000', textAlign: 'center' }}> #{selectedComplainData?.TicketID} </p>
        </div>
      </div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <h6  style={{color: '#fff', marginTop: '30px', }}> Created: </h6>

        <p  style={{color: '#fff', marginTop: '30px', }}> {moment(selectedComplainData?.Created).format("Do MMM YY")} </p>
      </div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <h6  style={{color: '#fff', marginTop: '30px', }}> Last message: </h6>

        <p  style={{color: '#fff', marginTop: '30px', }}> {isLastMessage?.text?.length > 20 ? `${isLastMessage?.text.slice(0, 20)}...` : isLastMessage?.text} </p>
      </div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <h6  style={{color: '#fff', marginTop: '30px', }}> Status: </h6>
        <div className="ticket-info-bedge" style={{backgroundColor: '#03781D'}}> 
        <p  style={{color: '#fff', textAlign: 'center'}}> {selectedComplainData?.Status} </p>
        </div>
      </div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <h6  style={{color: '#fff', marginTop: '30px', }}> Priority: </h6>
        <div className="ticket-info-bedge" style={{backgroundColor: '#D11C1C'}}> 
        <p  style={{color: '#fff', textAlign: 'center' }}> {selectedComplainData?.Priority} </p>
        </div>
      </div>
          </div>
          
          
          </div>
{/* 2nd card */}

<div className="row"> 
<form onSubmit={sendPrivateMessage} className="card-main">
<div>
  {/* First Message Block: Left-aligned */}
  <textarea
      style={{
        color: "#fff",
        backgroundColor: "transparent",
        border: "1px solid #D11C1C",
        padding: "10px",
        outline: "none",
        width: "100%",
        borderRadius: 5,
      }}
      value={text}
      onChange={(e) => setText(e.target.value)}
      placeholder="Write your response for the issue"
    />

<div style={{display: 'flex', flexDirection: 'row'}}>
{/* <div className="toggle-switch">
            <label className="switch">
                <input 
                    type="checkbox" 
                    checked={isToggled} 
                    onChange={() => setIsToggled(!isToggled)}
                />
                <span className="slider round"></span>
            </label>
        </div> */}
        {/* <p  style={{color: '#fff', marginTop: '30px', marginLeft: '10px'}}> Private </p> */}
            <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginRight: 20, flexDirection: 'row'}}> 
                {/* <label htmlFor="file-upload" className="statusBtn" style={{ backgroundColor: '#fff', color: '#000', display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '5px 10px', borderRadius: 5, border: '1px solid #ccc' }}>
              <img src={Attach} alt="Attach" style={{ marginRight: '5px' }} /> Attach
              <input id="file-upload" type="file" style={{ display: 'none' }} />
            </label> */}
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: 20}}> 
                <button className="statusBtn" type="submit"> Send </button>
            </div>
            
        </div>
</div>
</form>

<div className="card-main" style={{width: '20%'}}>
  {/* First Message Block: Left-aligned */}
  <h4 style={{color: '#fff'}}>  Requester Detail </h4>


    <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
    <img src={selectedComplainData?.ProfileImage ? `https://enigmakey.tech/serv/files/${selectedComplainData?.ProfileImage}` : Profile} style={{ width: 50, height: 50, borderRadius: 100}} alt="Back" />
    <div style={{flexDirection: 'column', marginLeft: '10px'}}> 
    <p  style={{color: '#fff', marginBottom: '0px'}}> {selectedComplainData.Username} </p>
    <p  style={{color: '#fff'}}> {selectedComplainData?.Email} </p>
    </div>
      </div>
          </div>
</div>

  </>
  );
}

export default SupportDetails;
