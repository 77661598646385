import React, { useEffect, useState } from "react";
import axios from "axios";
import LedgerTable from "../../Component/Table/LedgerTable";
import { useDispatch, useSelector } from "react-redux";
import LedgerMiddleware from "../../Redux/Middleware/LedgerMiddleware";
import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";
import HomeTop from "../../Component/Home/index";

const Home = () => {
  const { Ledgers } = useSelector((state) => state.ledger);
  const { allUsers } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const token = localStorage.getItem("userData");

  useEffect(() => {
    dispatch(LedgerMiddleware.getLedgerEntries());
    dispatch(AuthMiddleware.getAllUsers());
  }, [dispatch, token]);

  const [HEKSData, setHEKSData] = useState(null); // Use null for initial state

  const GetHEKSData = async () => {
    const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
    
    if (!token) {
      console.error("No token found!");
      return;
    }
  
    try {
      const headers = {
        Authorization: `Bearer ${token.trim()}`,
      };
  
  
      const response = await axios.get(`https://enigmakey.tech/serv/hek-stats`, {
        headers,
      });
  
      console.log("🚀 ~ GetHEKSData ~ response:", response.data);
  
      // Only update state if data is different to prevent re-renders
      setHEKSData((prevData) => {
        return JSON.stringify(prevData) !== JSON.stringify(response.data) ? response.data : prevData;
      });
    } catch (error) {
      console.error("🚀 ~ GetHEKSData ~ error:", error.response?.data || error.message);
    }
  };
  
  useEffect(() => {
    GetHEKSData();
  }, []); // No dependency array changes
  
  console.log("🚀 ~ HomeTop ~ HEKSData:", HEKSData);
  
  

  return (
    <div>
    <HomeTop HEKSData={HEKSData} />
      <LedgerTable
        data={[Ledgers.ledger_entries]}
        style={{ height: "100vh" }}
        allUsers={allUsers?.users}
      />
    </div>
  );
};

export default Home;
