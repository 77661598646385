import { GET_TICKETS, UPDATE_TICKET } from "../action_type";

const initialState = {
    allTickets: []
};

const TicketReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TICKETS:
            return {
                ...state,
                allTickets: action.payload,
            };

        case UPDATE_TICKET:
            return {
                ...state,
                allTickets: state.allTickets.map(ticket =>
                    ticket.id === action.payload.ticketId
                        ? { ...ticket, ...action.payload.updatedData }
                        : ticket
                ),
            };

        default:
            return state;
    }
};

export default TicketReducer;
