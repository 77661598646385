import React, { useState, useEffect } from "react";
import "./LedgerTable.css";
import Attachment from "../../assets/Attach.png";
import ArrTop from "../../assets/ArrowTop.png";
import Refresh from "../../assets/refresh.png";
import Dots from "../../assets/dots.png";
import Arrdown from "../../assets/Arrowdown.png";
import Filter from "../../assets/filter.png";
import Union from "../../assets/Union.png";
import { Oval } from 'react-loader-spinner';
import { CSVLink } from "react-csv";
import CSV from '../../assets/csv.png';
import { Modal, Button, Form } from 'react-bootstrap';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NoDataFound from '../../assets/data_not_found.png';

function LedgerTable({ data, style, allUsers }) {
  // console.log("🚀 ~ LedgerTable ~ allUsers:", allUsers);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    eventType: "",
    time: "",
    username: "",
  });
  const [filteredData, setFilteredData] = useState(data[0] || []);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setFilteredData(data[0] || []);
    if (data[0] && data[0].length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }, [data]);

  const windowRefresh = () => {
    window.location.reload();
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFilters({
      ...filters,
      time: date ? moment(date).format('YYYY-MM-DD') : "",
    });
  };

  const applyFilters = () => {
    let filtered = data[0] || [];

    // Filtering by eventType
    if (filters.eventType) {
      filtered = filtered.filter(el => el.data.event === filters.eventType);
    }

    // Filtering by time (date)
    if (filters.time) {
      filtered = filtered.filter(el => el.data.timestamp && el.data.timestamp.includes(filters.time));
    }

    // Filtering by username
    if (filters.username) {
      filtered = filtered.filter(el => el.data.details && el.data.details.toLowerCase().includes(filters.username.toLowerCase()));
    }

    setFilteredData(filtered);
    setIsFilterModalOpen(false);
  };

  const clearFilters = () => {
    setFilters({
      eventType: "",
      time: "",
      username: "",
    });
    setSelectedDate(null);
    setFilteredData(data[0] || []);
    setIsFilterModalOpen(false);
  };

  const formatTimestamp = (timestamp) => {
    return moment(timestamp).format('DD-MM-YYYY hh:mm:ss A');
  };

  return (
    <div className="tableContainer" style={style}>
      <div className="headerContainer">
        <div className="headerTitle">
          <h2 style={{ fontWeight: "bolder" }}>Ledger Events</h2>
          <button className="iconbutton">
            <img src={Attachment} width={15} height={15} alt="Attachment" />
          </button>
          <button className="iconbutton">
            <img src={ArrTop} width={12} height={12} alt="ArrTop" />
          </button>
        </div>
        <div className="headerTitle">
          <button className="iconbutton" onClick={windowRefresh}>
            <img src={Refresh}  alt="Refresh" style={{width: '20px', height: '20px'}} />
          </button>
        </div>
      </div>
      {filteredData.length > 0 ? (
        <>
          <div className="filterContainer">
            <div className="headerTitle">
              <button
                className="iconbutton"
                style={{ fontSize: 16, fontWeight: 'bold', color: '#fff' }}
                onClick={() => setIsFilterModalOpen(true)}
              >
                <img src={Filter} width={30} height={30} alt="Filter" /> Filter
              </button>
            </div>
          </div>
          <div className="table-scrollable">
            <table
              style={{
                width: "100%",
                margin: "13px",
                borderCollapse: "collapse",
                overflowX: 'auto',
              }}
            >
              <thead>
                <tr>
                  <th>Ledger ID</th>
                  <th>Event Type</th>
                  <th>HEK ID</th>
                  <th>User ID</th>
                  <th>Admin ID</th>
                  <th id="thReadBg">Time</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.slice().reverse().map((el, index) => (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>{el?.data.event}</td>
                    <td>{el?.data.hek_id ? el?.data.hek_id : "-"}</td>
                    <td>{el?.data.user_id}</td>
                    <td>{el?.data.admin_id}</td>
                    <td id='tdReadBg'>{el?.data.timestamp ? formatTimestamp(el.data.timestamp) : "-"}</td>
                    <td>{el?.data.details}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                margin: '100px auto',
              }}
            >
              <Oval
                visible={true}
                height="80"
                width="80"
                color="black"
                secondaryColor="red"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={NoDataFound} alt="no_data_found" />
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
              >
                <h5 style={{color: '#fff'}}> No data found try to reload the browser </h5>
                <button className="iconbutton" onClick={windowRefresh}>
                  <img src={Refresh} width={40} height={35} />
                </button>
              </div>
            </>
          )}
        </div>
      )}

      <Modal show={isFilterModalOpen} onHide={() => setIsFilterModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="eventType">
              <Form.Label>Event Type</Form.Label>
              <Form.Select
                name="eventType"
                value={filters.eventType}
                onChange={handleFilterChange}
              >
                <option value="">Select Event Type</option>
                <option value="add-user">add-user</option>
                <option value="update-user">update-user</option>
                <option value="delete-user">delete-user</option>
                <option value="login">login</option>
                <option value="user_vpn_config_update">user_vpn_config_update</option>
                <option value="add-hek">add-hek</option>
                <option value="delete-hek">delete-hek</option>
                <option value="hek-update">hek-update</option>
                <option value="hek-associated-with-user">hek-associated-with-user</option>
                <option value="decryption">decryption</option>
                <option value="encryption">encryption</option>
              </Form.Select>
            </Form.Group>
          
            <Form.Group controlId="username" style={{marginTop: '20px'}}>
              <Form.Label>Username</Form.Label>
              <Form.Select
                name="username"
                value={filters.username}
                onChange={handleFilterChange}
              >
                <option value="">Select Username</option>
                {allUsers?.map((user, index) => (
                  <option key={index} value={user.username}>
                    {user.username}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group
              controlId="time"
              style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', marginBottom: 15 }}
            >
              <Form.Label style={{ marginTop: 5 }}>Date</Form.Label>
              <div style={{ marginLeft: 15 }}>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsFilterModalOpen(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={applyFilters} style={{marginTop: '20px'}}>
            Apply Filters
          </Button>
          <Button variant="danger" onClick={clearFilters}>
            Clear Filters
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LedgerTable;
