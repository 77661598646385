import { GET_TICKETS, UPDATE_TICKET } from "../action_type";

export default class TicketAction {
    static GetAllTickets = (data) => {
        return {
          type: GET_TICKETS,
          payload: data.tickets,
        };
      };

    static UpdateTicket = (ticketId, updatedData) => {
        return {
          type: UPDATE_TICKET,
          payload: { ticketId, updatedData },
        };
      };
}
