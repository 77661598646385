import React, {useState, useEffect} from 'react';
import './HomeTop.css';
import MultiColorProgressBar from './MultiColorProgressBar';
import Tooltip from '../Tooltip/Tooltip';
import axios from 'axios';



const HomeTop = ({HEKSData}) => {
console.log("🚀 ~ HomeTop ~ HEKSData:", HEKSData)

  
 
const readings = [
  { name: 'Active', value: 60, color: '#392dca' },
  { name: 'Allowed', value: 7, color: '#ff881b' },
  { name: 'Inactive', value: 23, color: '#aca7e9' },
  { name: 'Blocked', value: 10, color: '#e23a45' }
];


  const tooltipContent = [
    { label: 'HEK ID', value: '102' },
    { label: 'User ID', value: '201' },
    { label: 'Status', value: 'Active' },
    { label: 'Location', value: 'San Diego, USA' }
  ];

  const allHEKS = HEKSData?.Active + HEKSData?.Allowed + HEKSData?.Inactive + HEKSData?.Blocked

        return (
        <div style={{backgroundColor: '#fff', paddingTop: '50px', paddingBottom: '200px'}}>
            <h4 style={{marginLeft: '20px'}}>Total HEKS </h4>
            <h2 style={{marginLeft: '20px'}}> {allHEKS} </h2>
          {/* Adjust this bar here */}
          <MultiColorProgressBar readings={readings} />
            <div className='heks-main'>
                <div className='dot' style={{ backgroundColor: '#392dca'}}></div>
                <p> Active </p>
                <p> {HEKSData?.Active} </p>
            </div>

            <div className='heks-main'>
                <div className='dot' style={{ backgroundColor: '#ff881b'}}></div>
                <p> Allowed </p>
                <p> {HEKSData?.Allowed} </p>
            </div>
            <div className='heks-main'>
                <div className='dot' style={{ backgroundColor: '#aca7e9'}}></div>
                <p> Inactive </p>
                <p> {HEKSData?.Inactive} </p>
            </div>
            <div className='heks-main'>
                <div className='dot' style={{ backgroundColor: '#e23a45'}}></div>
                <p> Blocked </p>
                <p> {HEKSData?.Blocked} </p>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '450px', marginBottom: '50px'}}> 
            <Tooltip content={tooltipContent}>
            <div className='dot' style={{ backgroundColor: '#aca7e9'}}></div>
            </Tooltip>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -200}}> 
            <Tooltip content={tooltipContent}>
            <div className='dot' style={{ backgroundColor: '#ff881b',}}></div> <br /> <br /> <br /> <br />
            </Tooltip>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
            <Tooltip content={tooltipContent}>
            <div className='dot' style={{ backgroundColor: '#392dca',}}></div> <br /> <br /> <br /> <br />
            </Tooltip>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '500px'}}> 
            <Tooltip content={tooltipContent}>
            <div className='dot' style={{ backgroundColor: '#e23a45'}}></div>
            </Tooltip>
            </div>
         </div>
    )
};


export default HomeTop;
