export const SIGNIN = "SIGNIN";
export const LOGOUT = "LOGOUT";
export const All_HEKS = "ALL_HEKS";
export const AVAILABLE_HEKS = "AVAILABLE_HEKS";
export const All_HEKS_WITH_IP = "All_HEKS_WITH_IP";
export const ASSOCIATE_HEKS_TO_USER = "ASSOCIATE_HEKS_TO_USER";
export const All_USERS_WITH_IP = "All_USERS_WITH_IP";
export const All_USERS = "ALL_USERS";
export const ADD_USERS = "ADD_USERS";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const LEDGER_ENTRIES = "LEDGER_ENTRIES";
export const VPN_CONFIG_GENERATE = "VPN_CONFIG_GENERATE";
export const GET_TICKETS = "GET_TICKETS";
export const UPDATE_TICKET = "UPDATE_TICKET";
